import { Component, OnInit } from '@angular/core';
import { CoreConfigService } from '@core/services/config.service';
import { CoreTranslationService } from '@core/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { ComunicationService } from 'app/shared/services/comunication.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-pick-language',
  templateUrl: './pick-language.component.html',
  styleUrls: ['./pick-language.component.scss']
})
export class PickLanguageComponent {

  public languageOptions: any;
  public selectedLanguage: any;
  
  constructor(
    private _translationService:CoreTranslationService,
    public _translateService: TranslateService,
    private _coreConfigService: CoreConfigService,
    private _comunicationService:ComunicationService,
  ) {
    this.languageOptions = {
      en: {
        title: 'English',
        flag: 'us'
      },
      'es-MX': {
        title: 'Español (México)',
        flag: 'mx'
      },
      'es-ES': {
        title: 'Español (España)',
        flag: 'es'
      },
      'es-CL': {
        title: 'Español (Chile)',
        flag: 'cl'
      },
      'en-US': {
        title: 'English',
        flag: 'us'
      }
    }
  }

  getLanguages(){
    var langOpt = new Map();
    this._translationService.getLanguages()
      .pipe(take(1))
      .subscribe(
        resData => {
          for (var item of resData){
            langOpt.set (item.code ,item);
          }
          // this.languageOptions = langOpt;
          // return langOpt;
      });
  }
  
  setLanguage(language): void {
    // Set the selected language for the navbar on change
    this.selectedLanguage = language;

    // Use the selected language id for translations
    this._translationService.getLocales(language).subscribe(resData=>{this._translationService.translate()})
    this._translateService.use(language);
    localStorage.setItem("language", language)

    this._comunicationService.getWhatsapp().subscribe(number =>  {
      var configs = {};
      configs['app'] = { appLanguage: language }
      if (number !=="") configs['contactInfo'] = {whatsapp:number}
    
      this._coreConfigService.setConfig(configs, { emitEvent: true });
    } )
  }

  //TODO: Validate if memorylag for subscribe
  //TODO: CSS in mobile when change language

}
