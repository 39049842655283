import { Component, OnDestroy, OnInit } from '@angular/core';
import { CoreConfigService } from '@core/services/config.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-whatsapp',
  template: `
  <a href="https://api.whatsapp.com/send?phone={{coreConfig.contactInfo.whatsapp}}" class="floating" target="_blank">
  <i class="fa-brands fa-whatsapp fa-flip" style="color: #ffffff;margin-top: 16px; --fa-animation-duration: 5s; --fa-animation-iteration-count: 1"></i>
  </a>
  `,
  styleUrls: ['./whatsapp.component.scss']
})
export class WhatsappComponent implements OnInit, OnDestroy{

  public coreConfig: any;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _coreConfigService: CoreConfigService,
    ){
    this._unsubscribeAll = new Subject(); 
    }

  ngOnInit(): void {
    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
