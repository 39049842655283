import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

export interface whatsapp {
  id?:string;
  number:string;
}

@Injectable({
  providedIn: 'root'
})
export class ComunicationService {

  constructor(
    private _http:HttpClient,
    ) { }

  getWhatsapp(){
    return this._http
      .get<any>(`${environment.apiUrl}/panel/whatsapp/client/` )
      .pipe(
        map(resData =>{
            let whatsapp = resData.number;
            if (whatsapp.substring(0,1) === "+") whatsapp = whatsapp.substring(1)
            return whatsapp;
          }
        )
      )
  }
}