import { Component, OnDestroy, OnInit } from '@angular/core';
import { GAnalyticsItem, ServiceCart } from 'app/orders/new/models/service.model';
import { OrdersService } from 'app/orders/service';

import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-navbar-cart',
  templateUrl: './navbar-cart.component.html',
  styleUrls: ['./navbar-cart.component.scss']
})
export class NavbarCartComponent implements OnInit, OnDestroy {
  // Public
  public servicesCart: ServiceCart[] = [];
  public totalPrice:number = 0;
  public currency:string = "USD"
  public servicesLength = 0;

  // Private
  private _unsubscribeAll: Subject<any>;
  private _activeSubscriptions:Subscription = new Subscription()

  constructor(public _ordersService: OrdersService) {    
  }

  onChangeService(serviceId:string, value:number) {
    
    const index = this.servicesCart.findIndex ( x => x.id === serviceId);
    if (value === 0 ){
      this.servicesCart.splice(index, 1);
    } else {
      this.servicesCart[index].quantity = value
    }
    this._ordersService.cartEmitter.next(this.servicesCart);

    //GAnalytics
    let AnalyticsItems: GAnalyticsItem[]= [];
    this.servicesCart.forEach(element =>
      {
        let Item:GAnalyticsItem = new GAnalyticsItem;
        Item.item_id = element.id;
        Item.item_name = element.name;
        Item.price = Number(element.prices.price);
        Item.quantity = element.quantity;
        AnalyticsItems.push(Item);
      })
    
    window['gtag']('event', 'select_item', {
      items:AnalyticsItems
    });

  };
  
  ngOnInit(): void {
    this._activeSubscriptions = this._ordersService.cartEmitter.subscribe(cart => {
      this.servicesCart =[];
      this.totalPrice = 0;
      this.servicesLength = 0;
      cart.forEach ( service => {
        this.totalPrice += (service.quantity * Number(service.prices.price));
        this.currency = service.currency;
        this.servicesLength += service.quantity;
       })
      this.servicesCart = cart;
    })
  }

  ngOnDestroy(): void {
    this._activeSubscriptions.unsubscribe
  }


}
