<li ngbDropdown #cartDropdown class="nav-item dropdown-cart mr-25">
  <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarCartDropdown"
    ><span [data-feather]="'shopping-cart'" [class]="'ficon'"></span
    ><span class="badge badge-pill badge-primary badge-up cart-item-count">{{ servicesLength }}</span></a
  >
  <ul
    ngbDropdownMenu
    aria-labelledby="navbarCartDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right"
  >
    <!-- Cart header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">{{'service' | translate | pluralize:servicesLength}}</h4>
        <div class="badge badge-pill badge-light-primary ml-1">{{ servicesLength }} {{'service' | translate | pluralize:servicesLength | lowercase }}</div>
      </div>
    </li>
    <!--/ Cart header -->

    <!-- Cart content -->
    <li class="scrollable-container media-list">
      <ng-container *ngIf="servicesLength">
        <ng-container *ngFor="let service of servicesCart">
          <div class="media align-items-center">
            <div class="media-body mt-1">
              <button class="btn p-0" (click)="onChangeService(service.id, 0)">
                <i class="ficon cart-item-remove" data-feather="x"></i>
              </button>
              <div class="media-heading">
                <h6 class="cart-item-title">
                  <span clspanss="text-body">{{service.name}}</span>
                </h6>
              </div>
              <div class="cart-item-qty">
                <div class="input-group touchspin-cart" *ngIf="service.max_quantity>1">
                  <core-touchspin [numberValue]="service.quantity" [stepValue]="1" [minValue]="0" [maxValue]="service.max_quantity" (onChange)="onChangeService(service.id, $event)"></core-touchspin>
                </div>
              </div>
              <h5 class="cart-item-price ml-auto">{{ (service.quantity * service.prices.price) | currency:service.currency: " $" }} </h5>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </li>
    <!--/ Cart content -->

    <!-- Cart footer -->
    <li class="dropdown-menu-footer">
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">Total</h6>
        <h6 class="text-primary font-weight-bolder mb-0">{{ totalPrice | currency:currency: " $" }} </h6>
      </div>
      <a class="btn btn-primary btn-block" routerLink="/new">{{'goto' | translate }} {{'checkout' | translate | lowercase}}</a>
    </li>
    <!--/ Cart footer -->
  </ul>
</li>
