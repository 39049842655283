import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export interface Locale {
  lang: string;
  data: Object;
}

interface Language {
  flag:string;
  id:string;
  title:string;
}

export interface Languages extends Array<Language> {}

@Injectable({
  providedIn: 'root'
})
export class CoreTranslationService {

  private _header:HttpHeaders;
  private _entity_path = "translation";
  private _data_key ="locales";
  
  constructor(private _translateService: TranslateService,
    private _http:HttpClient
    ) {}

  getLanguages(){
    return this._http.get<any>(
    `${environment.apiUrl}/${this._entity_path}/get-translation-languages`,
    { headers: this._header }
    ).pipe(
      map(responseData => {
        var languageOptions = [];
        for (const language of responseData.languages){
          languageOptions.push({
            code:language.code,
            title:language.name,
            flag:language.code.substring(3,5),
            id:language.code.substring(0, 2)})
          };
        return languageOptions; 
        })
        )     
  }

  getLocales(lang=null): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("app",4)
    if (lang){
      queryParams = queryParams.append("lan",lang)
    }

    return this._http.get<any>(
      `${environment.apiUrl}/${this._entity_path}/get-translation`,
      { headers: this._header,
      params: queryParams }).pipe(
        tap(
          result => {
            let locales = [];
            for(let locale of result) {
              locales.push({ lang: locale.lang, data: locale.result })
              this._translateService.setTranslation(locale.lang, locale.data, true);
            };
            localStorage.removeItem(this._data_key);
            localStorage.setItem(this._data_key, JSON.stringify(locales));
        }
      ),
      map(result => {
        let locales = [];
        for(let locale of result) { locales.push({ lang: locale.lang, data: locale.result }) }
        return locales;
    }))
    
  }

  translate(...args: Locale[]): void {
    
    var locales = JSON.parse(localStorage.getItem(this._data_key));

    if (locales){locales.forEach(locale => {
      // use setTranslation() with the third argument value as true to append translations instead of replacing them
      this._translateService.setTranslation(locale.lang, locale.data, false);
    });
  }
  }
}
