import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'changeGenderWord'
})
export class ChangeGenderWordPipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    if (value.slice(-1) === "o") return value.replace(/.$/,"a");
    if (value.slice(-2) === "os") return value.replace(/.{0,2}$/,"as");
    return value;
  }

}