import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { AuthenticationService } from 'app/auth/service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-clearcache',
  templateUrl: './clearcache.component.html',
  styleUrls: ['./clearcache.component.scss']
})
export class ClearcacheComponent implements OnInit, OnDestroy {
  
  public coreConfig: any;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _coreConfigService: CoreConfigService,
    private _router:Router,
    private _authenticationService:AuthenticationService,
  ) {
    this._unsubscribeAll = new Subject();
    this._coreConfigService.config = {
      layout: {navbar: {hidden: true},
      footer: {hidden: true},
      menu: {hidden: true},
      customizer: false,
      enableLocalStorage: false
      }

   }
  }

  ngOnInit(): void {
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
    
    this._authenticationService.logout();
    localStorage.clear();
    sessionStorage.clear();
    
    setTimeout(() => {
      this._router.navigate(['/']);
    }, 5000);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
