import { Price } from "app/agenda/models";
import { Branch } from "app/locations/models/branch.model";


export class ServiceCart {
    id: string;
    name:string;
    image: string;
    description: string;
    branches: Branch[];
    prices:Price;
    max_quantity: number;
    currency: string;
    quantity?: number = 0;
}

export class GAnalyticsItem {
    item_id: string;
    item_name: string;
    price: number;
    quantity?: number;
}