import { Role } from './role';

export class User {
  id                : number;
  email             : string;
  first_name        : string;
  last_name         : string;
  default_location?  : string;
  default_payment_gateway?:  string;
  role?              : Role;
  social_type?       : number;
  identity?          : string;
  gender             : string;
  birthday?          : string;
  phone              : string;
  country_code       : string;
  dial_code?         : string;
}

export class SignupData{
  first_name         : string;
  last_name          : string;
  gender             : string;
  birthday           : string;
  email              : string;
  password           : string;
  confirm_password   : string;
  phone              : string;
  country_code       : string;
  dial_code          : string;
}
