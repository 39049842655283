<!-- Footer -->
<p class="clearfix mb-0">
  <span class="float-md-left d-block d-md-inline-block mt-25">
    <span>{{'byUsingOurService' | translate }}</span>
    <span><a href="{{'termsLink'| translate}}" target="_blank"> {{'termsAndConditions' | translate}} </a></span>
    <span>{{'andOur' | translate}}</span>
    <span><a href="{{'privacyPolicyLink' | translate}}" target="_blank"> {{'privacyPolicy' | translate}}.</a></span>
  </span>
</p>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>