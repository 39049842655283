// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  hmr: false,
  apiUrl: 'https://api.prowashgo.com/api/v1',
  // apiUrl: 'http://0.0.0.0:8000/api/v1',
  apiImageUrl:'https://d3aqgect0zhwpq.cloudfront.net',
  googleAPIkey:'AIzaSyDpMReRPXpcJ7SzD8vNX_jqnWreaO6syvM',
  googleLoginID: '360048029394-hkhk5fcgbhqi6mfspan8fo3e1ln2j01d.apps.googleusercontent.com',

  stripeUrl: 'https://api.stripe.com/v1',
  stripePublicToken: 'pk_live_51KO0bhB84NwIvVccejMEyWr8NeIYivMjqSwQTkEmBeQNgFOywrmQpIyG8OZEoY9rVHUY21itmz9dQvvvGInChF7B00eQk9pgis',
  version: '1.0.0',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
