import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreCommonModule } from '@core/common.module';
import { CommonModule } from '@angular/common';

import { PickLanguageComponent } from './components/pick-language/pick-language.component';
import { WhatsappComponent } from './components/whatsapp/whatsapp.component';
import { ClearcacheComponent } from './pages/clearcache/clearcache.component';
import { NotAuthorizedComponent } from './pages/not-authorized/not-authorized.component';

const routes: Routes = [
  {
  path: 'clear-cache',
  component: ClearcacheComponent,
  data: { animation: 'misc', title:'Clear Cache' }
  },
  {
    path: 'not-authorized',
    component: NotAuthorizedComponent,
    data: { animation: 'misc', title: 'notAuthorized' }
  },
]

@NgModule({
  declarations: [
    PickLanguageComponent,
    WhatsappComponent,
    ClearcacheComponent,
    NotAuthorizedComponent,
  ],
  imports: [
    CommonModule,
    CoreCommonModule,
    NgbModule,
    RouterModule.forChild(routes)
  ],

  exports:[PickLanguageComponent, WhatsappComponent]
})
export class SharedModule { }
