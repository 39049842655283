<!-- Not authorized-->
<div class="misc-wrapper">
    <div class="brand-logo">
        <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="36" />
        <span class="ml-1">
            <img src="{{ coreConfig.app.appLogoFull }}" alt="Logo" style="width: 180px;"/>
        </span>
    </div>
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">{{'notAuthorized' | translate }}🔐</h2>
        <div class="mb-2">
            {{'notAuthorized.Phrase' | translate }}
        </div>
        <div>
            <a class="btn btn-primary mb-1 btn-sm-block" routerLink="/auth" rippleEffect>{{ 'login' | translate }}</a>
        </div>
        <img
          class="img-fluid"
          src='assets/images/pages/not-authorized-dark.svg'
          alt="Not authorized page"
        />
      </div>
    </div>
  </div>
  <!-- / Not authorized-->
  