<div class="navbar-container d-flex content w-100 ">
  <div class="bookmark-wrapper d-flex align-items-center mr-auto">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item my-auto">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->
  </div>

  <div class="d-flex align-items-center">
    <img src="assets/images/logo/logoFull.png" alt="Logo" style="width: 10rem;"/>
  </div>

  <ul class="nav navbar-nav align-items-center ml-auto">
    <!-- <li class="nav-item d-none d-lg-block mr-1">
      <a class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
        <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
      </a>
    </li> -->
    <app-navbar-cart class="mr-1"></app-navbar-cart>
    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a
        class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div class="user-nav d-sm-flex d-none">
          <span class="user-name font-weight-bolder">{{currentUser?.first_name}}</span><span class="user-status">{{currentUser?.email}}</span>
        </div>
        <span class="avatar">
          <span class="avatar-content text-white">{{ currentUser?.first_name | initials | uppercase }}</span>
        </span>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right" style="width: 250px !important;">
        <span ngbDropdownItem class="user-name font-weight-bolder">{{'welcome' | translate}} {{currentUser?.first_name}}!</span>
        <a ngbDropdownItem [routerLink]="['/user']"><span [data-feather]="'settings'" [class]="'mr-50'"></span> {{'profile' | translate}}</a>
        <div class="dropdown-divider"></div>
        <a ngbDropdownItem href="https://wa.me/{{coreConfig.contactInfo.whatsapp}}" target="_blank" *ngIf="coreConfig.contactInfo.whatsapp && device"><i class ="fa-brands fa-whatsapp mr-50"></i> {{'contactUs' | translate}}</a>
        <a ngbDropdownItem href="mailto:{{coreConfig.contactInfo.email}}" target="_blank"><span [data-feather]="'mail'" [class]="'mr-50'"></span> {{'contactUs' | translate}}</a>
        <!-- <a ngbDropdownItem ><span [data-feather]="'message-square'" [class]="'mr-50'"></span> Chats</a> -->
        <div class="dropdown-divider"></div>
        <div style="padding: 0.65rem 0.78rem ;"><app-pick-language></app-pick-language></div>
        <div class="dropdown-divider"></div>
        <a ngbDropdownItem (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50'"></span> {{'logout' | translate}}</a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>

<!-- <div class="header-navbar navbar-static-top" style="min-height: 1px;">
  <ngb-alert [type]="'danger'" [dismissible]="false" style="margin-bottom: 0px;">
    <div class="alert-body"><strong>Good Morning!</strong> Start your day with some alerts.</div>
  </ngb-alert>
</div> -->
