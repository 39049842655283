import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  // {
  //   id: 'home',
  //   title: 'Home',
  //   translate: 'MENU.HOME',
  //   type: 'item',
  //   icon: 'home',
  //   url: 'home'
  // },
  {
    id: 'newService',
    title: 'New Service',
    translate: 'newService',
    quantity: 1,
    type: 'item',
    faicon: 'fa-solid fa-circle-plus',
    url: 'new'
  },
  {
    id: 'vehicle',
    title: 'Vehicle',
    translate: 'vehicle',
    type: 'item',
    faicon:'fa-solid fa-car-side',
    url: 'vehicles'
  },
  {
    id: 'location',
    title: 'Location',
    translate: 'location',
    type: 'item',
    faicon: 'fa-solid fa-location-dot',
    url: 'locations'
  },
  {
    id: 'agenda',
    title: 'Agenda',
    translate: 'agenda',
    quantity: 1,
    type: 'item',
    faicon: 'fa-regular fa-calendar',
    url: 'agenda'
  },
  {
    id: 'paymentMethod',
    title: 'Payment methods',
    translate: 'paymentMethod',
    type: 'item',
    faicon: 'fa-solid fa-credit-card',
    url: 'payment-methods'
  },
  {
    id: 'coupon',
    title: 'Coupon',
    translate: 'coupon',
    type: 'item',
    faicon: 'fa-solid fa-tags',
    url: 'coupons'
  },

]
