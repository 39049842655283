import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Params } from '@angular/router';

import { AuthenticationService } from 'app/auth/service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService) {}
  
  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : boolean | Promise <boolean> | Observable <boolean> {
    return this._authenticationService.currentToken
      .pipe(map(user => {
        if (!user) {
          let url: {url:string, queryParams?:Params} = {url:''};
          url.url = state.url.indexOf("?")>0 ? state.url.substring( 0 , state.url.indexOf("?") ) : state.url;
          url.queryParams = route.queryParams; 
          this._authenticationService.currentUrl.next(url);
          (url.url==='/' || url.url==='/new') ? this._router.navigate(['/auth']) : this._router.navigate(['/other/not-authorized']);
          return false;
        }
        this._authenticationService.currentUrl.next(null);
        return !!user
      }));
  }
}
