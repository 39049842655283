import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Location, Tag } from '../models/';
import { environment } from 'environments/environment';
import { Pagination } from 'app/shared/models/pagination.model';
import { catchError, map, tap } from 'rxjs/operators';
import { BehaviorSubject, Subject, throwError } from 'rxjs';

export interface LocationResponse {
  data:Location[];
  status_code: number;
  pagination:Pagination
}

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  itemEmitter = new BehaviorSubject(null);

  constructor(private _http:HttpClient,) { }
  
  public listItems(page?:number, perpage?:number, search?:string){
    let queryParams = new HttpParams();
    if (page) {queryParams = queryParams.append('page',page)};
    if (perpage) {queryParams = queryParams.append('perpage',perpage)};
    if (search) {queryParams = queryParams.append('search',search)};
    return this._http.get<LocationResponse>(`${environment.apiUrl}/location/`, {params: queryParams})
  }

  public coverage_point(lat:number, lng:number){
    let queryParams = new HttpParams();
    if (lat) {queryParams = queryParams.append('lat',lat)};
    if (lng) {queryParams = queryParams.append('lng',lng)};
    return this._http.get<any>(`${environment.apiUrl}/branch-office/coveragepoint/`, {params: queryParams})
  }
  
  public coverage(location:string){
    let queryParams = new HttpParams();
    if (location) {queryParams = queryParams.append('location',location)};
    return this._http.get<any>(`${environment.apiUrl}/branch-office/coverage/`, {params: queryParams})
  }

  public deleteItem(id:string){
    return this._http.delete(`${environment.apiUrl}/location/${id}`)
    .pipe(catchError(this.handleError))
  }

  public getItem(id:string){
    return this._http.get<Location>(`${environment.apiUrl}/location/${id}`)
  }

  public createItem(location:Location){
    return this._http.post<any>(`${environment.apiUrl}/location/`, location)
    .pipe(catchError(this.handleError))
  }

  public editItem(location:Location){
    return this._http.put<any>(`${environment.apiUrl}/location/${location.id}/`, location)
    .pipe(catchError(this.handleError))
  }

  public getTags(){
    return this._http.get<Tag[]>(`${environment.apiUrl}/tag/`)
      .pipe(
        map((tag:Tag[]) => {
          return tag.map(t=>({id:t.id, name:t.name}))
        })
      )
  }

  /// HandleErrors
  private handleError(errorResp: HttpErrorResponse){
    let error ="Unknown Error";
            if (!errorResp.error.errors || !errorResp.error.errors.join() ){
              return throwError(error);
            }
            error = errorResp.error.errors.join();
            return throwError(error)
  }
}
