<li ngbDropdown class="dropdown dropdown-language list-unstyled">
    <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
      <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang]?.flag }}"></i
      ><span class="selected-language">{{ languageOptions[_translateService.currentLang]?.title }}</span></a
    >
    <ul ngbDropdownMenu aria-labelledby="dropdown-flag" class="dropdown-menu">
      <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
        <i class="flag-icon flag-icon-{{ languageOptions[lang]?.flag }}"></i> {{ languageOptions[lang]?.title }}
      </a>
    </ul>
</li>
