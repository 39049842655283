import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/helpers';

const appRoutes: Routes = [
    {
      path: 'auth',
      loadChildren: () => import('./auth/authentication.module').then(m => m.AuthenticationModule),
      title: 'Login',
      data: { title: 'Login' }
    },
    {
      path: 'user',
      loadChildren: () => import('./auth/user/user.module').then(m => m.UserModule),
      canActivate:[AuthGuard],
      title: 'profile',
      data: { title: 'profile' }
    },
    {
      path: 'locations',
      loadComponent: () => import('./locations/pages/list/location-list-wrapper/location-list-wrapper.component').then(m => m.LocationListWrapperComponent),
      canActivate:[AuthGuard],
      title: 'location',
      data: { title: 'location' }
    },
    {
      path: 'vehicles',
      loadComponent: () => import('./vehicles/pages/list/vehicle-list-wrapper/vehicle-list-wrapper.component').then(m => m.VehicleListWrapperComponent),
      canActivate:[AuthGuard],
      title: 'vehicle',
      data: { title: 'vehicle' }
    },
    {
      path: 'agenda',
      loadChildren: () => import('./agenda/agenda.module').then(m => m.AgendaModule),
      canActivate:[AuthGuard],
      title: 'agenda',
      data: { title: 'agenda' }
    },
    {
      path: 'coupons',
      loadComponent: () => import('./coupon/pages/list/coupon-list-wrapper/coupon-list-wrapper.component').then(m => m.CouponListWrapperComponent),
      canActivate:[AuthGuard],
      title: 'coupon',
      data: { title: 'coupon' }
    },
    {
      path: 'payment-methods',
      loadComponent: () => import('.//payment-methods/pages/list/payment-method-list-wrapper/payment-method-list-wrapper.component').then(m => m.PaymentMethodListWrapperComponent),
      canActivate:[AuthGuard],
      title: 'paymentMethod',
      data: { title: 'paymentMethod' }
    },
    {
      path: 'new',
      loadChildren: () => import('./orders/new/new.module').then(m => m.NewModule),
      canActivate:[AuthGuard],
      title: 'newService',
      data: { title: 'newService' }
    },
    {
      path: 'pages',
      loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule),
      title: 'pages' ,
      data: { title: 'pages' }
    },
    {
      path: 'other',
      loadChildren: () => import('./shared/shared.module').then(m => m.SharedModule),
      title: 'others',
      data: { title: 'others' }
    },
    {
      path: '',
      redirectTo: '/new',
      pathMatch: 'full'
    },
    {
      path: '**',
      redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
    },
  ];

  @NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
  })
  export class AppRoutingModule {}

  //pages/miscellaneous/coming-soon
  