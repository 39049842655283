import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { Brand, Model, Settings, Vehicle } from '../models/';
import { Pagination } from 'app/shared/models/pagination.model';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, Subject, throwError } from 'rxjs';

export interface VehicleResponse {
  data:Vehicle[];
  status_code: number;
  pagination:Pagination
}

export interface SettingResponse {
  data:Settings[];
  status_code: number;
  pagination:Pagination
}

@Injectable({providedIn:'root'})
export class VehicleService {

  itemEmitter = new BehaviorSubject(null);

  constructor(private _http:HttpClient,) { }
  
  public listItems(page?:number, perpage?:number, search?:string){
    let queryParams = new HttpParams();
    if (page) {queryParams = queryParams.append('page',page)};
    if (perpage) {queryParams = queryParams.append('perpage',perpage)};
    if (search) {queryParams = queryParams.append('search',search)};
    return this._http.get<VehicleResponse>(`${environment.apiUrl}/vehicle/`, {params: queryParams})
  }
  
  public deleteItem(id:string){
    return this._http.delete(`${environment.apiUrl}/vehicle/${id}`)
    .pipe(catchError(this.handleError))
  }

  public createItem(vehicle:Object){
    return this._http.post<any>(`${environment.apiUrl}/vehicle/`, vehicle)
    .pipe(catchError(this.handleError))
  }

  public getItem(id:string){
    return this._http.get<Vehicle>(`${environment.apiUrl}/vehicle/${id}`)
  }

  public listBrands(){
    return this._http.get<Brand[]>(`${environment.apiUrl}/vehicle/brand/`)
  }

  public listModels(brand?:string){
    let queryParams = new HttpParams();
    if (brand) {queryParams = queryParams.append('brandId',brand)};
    return this._http.get<Model[]>(`${environment.apiUrl}/vehicle/model/`,{params: queryParams})
  }

  public settings(brand:string,model:string){
    let queryParams = new HttpParams();
    if (brand) {queryParams = queryParams.append('brand',brand)};
    if (model) {queryParams = queryParams.append('model',model)};
    return this._http.get<SettingResponse>(`${environment.apiUrl}/vehicle/settings/`,{params: queryParams}).pipe(
      catchError(this.handleError)
    )
  }

  /// HandleErrors
  private handleError(errorResp: HttpErrorResponse){
    let error ="Unknown Error";
            if (errorResp.status === 404) return throwError("Brand - Model not found");
            if (!errorResp.error.errors || !errorResp.error.errors.join() ){
              return throwError(error);
            }
            error = errorResp.error.errors.join();
            return throwError(error)
  }
}
