import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServiceCart } from "../new/models/service.model";
import { Pagination } from "app/shared/models/pagination.model";
import { environment } from "environments/environment";
import { BehaviorSubject, Subject, throwError } from "rxjs";
import { FormControl, FormGroup } from "@angular/forms";
import { catchError } from "rxjs/operators";
import { Order } from "app/agenda/models";
import { NewService } from "../models";

export class AvailableHourRequest {
    branches:string[] = [];
    duration:number = 0;
}

  export interface ServiceResponse {
    data:ServiceCart[];
    status_code: number;
    pagination:Pagination
  }

export interface AvailableHour {
    hour: string;
    branch: string[];
}
export interface AvailableScheduleResponse {
    data: AvailableHour[];
}

export interface BillingServiceResponse {
    is_billing_service_available: boolean;
    billing_service: string;
}

@Injectable({providedIn:"root"})
export class OrdersService {

    newServiceStepBack = new Subject<void>; //To go back in schedule flow
    formDataEmiter = new BehaviorSubject<FormGroup>(new FormGroup({id: new FormControl("")} )); //This contains all information to be submitted in New Service; 
    availableHourEmitter = new BehaviorSubject(new AvailableHourRequest); //This has all available hours
    cartEmitter = new BehaviorSubject<ServiceCart[]>([]); // This contains services requested
    dateHourEmitter = new BehaviorSubject<{date:string, time_zone:string, branch:string}>(null); //This contains datehour and branch requested

    constructor(
        private _http:HttpClient,
    ){}

    public getServicesList(location:string, category:string, page?:number, perpage?:number, search?:string){
        let queryParams = new HttpParams();
        queryParams = queryParams.append('location',location);
        queryParams = queryParams.append('category',category);
        if (page) {queryParams = queryParams.append('page',page)};
        if (perpage) {queryParams = queryParams.append('perpage',perpage)};
        if (search) {queryParams = queryParams.append('search',search)};
        return this._http.get<ServiceResponse>(`${environment.apiUrl}/services/`, {params: queryParams})
    }

    public getAvailableSchedule(date:string, time_zone:string, body:AvailableHourRequest){
        let queryParams = new HttpParams();
        if (date) {queryParams = queryParams.append('date',date)};
        if (time_zone) {queryParams = queryParams.append('time_zone',time_zone)};
        return this._http.post<AvailableScheduleResponse>(`${environment.apiUrl}/availability/available-schedule/`, body, {params: queryParams})
        .pipe(catchError(this.handleError));
    }

    public getOrderTotal(body:any){
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post<any>(`${environment.apiUrl}/schedule-wash-service/order_total/`, body, {headers: headers})
    } 

    public getBillingService(id:string){
        return this._http.get<BillingServiceResponse>(`${environment.apiUrl}/billing-service-by-branch/${id}/`)
    }

    public createNewService(body: NewService){
        return this._http.post<Order>(`${environment.apiUrl}/schedule-wash-service/`, body )
        .pipe(catchError(this.handleError));
    }

    /// HandleErrors
    private handleError(errorResp: HttpErrorResponse){
        let error ="Unknown Error";
        if (!errorResp.error.errors || !errorResp.error.errors.join() ){
            return throwError(error);
        }
        error = errorResp.error.errors.join();
        return throwError(error)
  }

}