import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import { CoreConfigService } from '@core/services/config.service';
// import { take } from 'rxjs/operators';

@Injectable()
export class AceptedLanguageInterceptor implements HttpInterceptor {

  // private _coreConfig:any
  // ToDo: Implement an observable value with CoreConfig.
  
  constructor(
    // private _coreConfigService: CoreConfigService,
    ) {
    // this._coreConfigService.config
    //   .pipe(take(1))
    //   .subscribe(config => {this._coreConfig = config;})
  }   

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const appLanguage = localStorage.getItem("language")
    const modifiedRequest = request.clone({
      setHeaders: {
        'Accept-Language': appLanguage
      }
    })
    return next.handle(modifiedRequest);
  }
}
