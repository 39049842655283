<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Basic Alerts start -->
    <section id="home-page">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">{{'launchingSoon'| translate}} 🚀</h4>
            </div>
            <div class="card-body pb-1">
              <p class="card-text">All the best for your new project.</p>
              <p class="card-text">
                Please make sure to read our
                <a
                  href="https://www.google.com"
                  target="_blank"
                  >Template Documentation</a
                >
                to understand where to go from here and how to use our template.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Basic Alerts end -->
  </div>
</div>

<!-- Home TODO
Servicios:
- Pago Fallido schedule-wash-service/failed_payment
- Ordenes por calificar: schedule-wash-service/orders_to_rate
- Ordenes en curso: schedule-wash-service/?status=INCOURSE
- Último servicio: schedule-wash-service/lastwash
- Banner publicidad: advertisingbanner/userbanners
- POST device: devices {token-type}
- método de pago: panel/payment-method/clients-by-location -->
