<div class="misc-wrapper">
    <div class="brand-logo">
      <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="36" />
      <span class="ml-1">
        <img src="{{ coreConfig.app.appLogoFull }}" alt="Logo" style="width: 180px;"/>
      </span>
    </div>
    <div class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
            <h2 class="mb-2">Cache Cleared!</h2>
            <img
                class="img-fluid"
                height="300"
                src="assets/images/pages/graphic-6.png"
                alt="Coming soon page"
            /> 
        </div>
    </div>
</div>
  