import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, AuthResponseData, ValidationCode, ValidatedCode, UpdatedPassword, SignupData } from 'app/auth/models';
import { jwtDecode } from 'jwt-decode';
import { Params } from '@angular/router';

import { SocialAuthService, SocialUser } from "@abacritt/angularx-social-login";
import { LocationService } from 'app/locations/service/location.service';
import { VehicleService } from 'app/vehicles/service/vehicle.service';
import { OrdersService } from 'app/orders/service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  
  public currentUser: Observable<User>;
  private currentUserSubject: BehaviorSubject<User>;

  public currentToken: Observable<string>;
  private currentTokenSubject: BehaviorSubject<string>;

  public currentUrl: BehaviorSubject <{url:string, queryParams?:Params}> = new BehaviorSubject(null);

  constructor(private _http: HttpClient,
    private _authService: SocialAuthService,
    private _locationService: LocationService,
    private _vehicleService: VehicleService,
    private _ordersService: OrdersService,
    ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

    this.currentTokenSubject = new BehaviorSubject<string>(JSON.parse(localStorage.getItem('currentUserToken')));
    this.currentToken = this.currentTokenSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get currentTokenValue(): string {
    return this.currentTokenSubject.value;
  }

  // get isAdmin() {
  //   return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  // }

  // get isClient() {
  //   return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  // }

  /// Validation Token
  validToken(token){
    const tokenInfo = this.getDecodedAccessToken(token); // decode token
    const rolAdminAndSuperAdmin = tokenInfo.role;
    return (rolAdminAndSuperAdmin==6 || rolAdminAndSuperAdmin==5 || rolAdminAndSuperAdmin==3)?true:false;
  }

  getDecodedAccessToken(token: string): any {
    try {
      console.log(jwtDecode(token))
      return jwtDecode(token);
    } catch(Error) {
      return null;
    }
  }

  /// Login Services
  public login(email: string, password: string) {
    return this._http
      .post<AuthResponseData>(`${environment.apiUrl}/token_client/`, { email, password })
      .pipe(
        catchError(this.handleError),
        tap(token => {
          if (token && token.access) {
            this.currentTokenSubject.next(token.access);
            localStorage.setItem('currentUserToken', JSON.stringify(token.access));
          }
        }),
        mergeMap(token => this.me())
      );
  }

  public socialLogin(user: SocialUser, social_type="2"){
    
    const userInfo:{
      email: string,
      first_name: string,
      last_name: string,
      social_type: string,
      social_token: string,
      phone: string,
      country_code: string
    } = {
      email: user.email,
      first_name: user.firstName,
      last_name: user.lastName,
      social_type: social_type,
      social_token: user.idToken,
      phone: '0000000000',
      country_code: 'NA'
    };

    return this._http
    .post<AuthResponseData>(`${environment.apiUrl}/social_login/`, userInfo)
    .pipe(
      catchError(this.handleError),
      tap(token => {
        if (token && token.access) {
          this.currentTokenSubject.next(token.access);
          localStorage.setItem('currentUserToken', JSON.stringify(token.access));
        }
      }),
      mergeMap(token => this.me())
    );
  }

  public me() {
    return this._http.get<User>(`${environment.apiUrl}/me/`)
      .pipe(
        map(user => {
          let userResponse = new User()
          userResponse = user;
          sessionStorage.setItem('currentUser', JSON.stringify(userResponse));
        this.currentUserSubject.next(userResponse);
        return userResponse.id;
        })
      )
  }

  public deleteMe(id:any){
    return this._http.delete(`${environment.apiUrl}/users/${id}`)
      .pipe(
        tap(user => {
          sessionStorage.removeItem('currentUser');
          localStorage.removeItem('currentUserToken');
          this.currentUserSubject.next(null);
          this.currentTokenSubject.next(null);
        })
      )
  }

  public updateMe(user: User){
    let id = this.currentUserSubject.getValue().id;
    return this._http
      .patch<User>(`${environment.apiUrl}/users/${id}/`, user)
      .pipe(
        catchError(this.handleError),
        tap(user => {
          if (user) {
            let userResponse = new User()
            userResponse = user;
            sessionStorage.setItem('currentUser', JSON.stringify(userResponse));
            this.currentUserSubject.next(userResponse);
          }
        }),
      )
  }

  /// Logout Services
  public logout(): void {
    sessionStorage.removeItem('currentUser');
    localStorage.removeItem('currentUserToken');
    localStorage.removeItem('location');
    localStorage.removeItem('vehicle')
    this._locationService.itemEmitter.next(null);
    this._vehicleService.itemEmitter.next(null);
    this._ordersService.dateHourEmitter.next(null);
    this._ordersService.availableHourEmitter.next(null)
    this._ordersService.cartEmitter.next([]);
          
    this._authService.signOut().catch(error => {})
    this.currentUserSubject.next(null);
    this.currentTokenSubject.next(null);
  }

  /// Recovery Password Services
  public requestRecoveryCode(email:String): Observable<ValidationCode> {
    return this._http.post<ValidationCode>(`${environment.apiUrl}/verification_code/`, { email })
    .pipe(catchError(this.handleError));
  }

  public validatePin(verification_code:String): Observable<ValidatedCode> {
    return this._http.post<ValidatedCode>(`${environment.apiUrl}/validation_code/`, { verification_code })
    .pipe(catchError(this.handleError)); 
  }

  public resetPassword(token:String, password:String, confirm_password:String): Observable<UpdatedPassword> {
    return this._http.post<UpdatedPassword>(`${environment.apiUrl}/recover_password/`, { token, password, confirm_password })
    .pipe(catchError(this.handleError));
  }

  // Register Services
  public signup(data: SignupData):Observable<SignupData> {
    return this._http.post<SignupData>(`${environment.apiUrl}/register/`, data )
    .pipe(catchError(this.handleError));
  }

  /// HandleErrors
  private handleError(errorResp: HttpErrorResponse){
    let error ="Unknown Error";
            if (!errorResp.error.errors || !errorResp.error.errors.join() ){
              return throwError(error);
            }
            error = errorResp.error.errors.join();
            return throwError(error)
  }
  
}

