import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'pluralize'
})
export class PluralizePipe implements PipeTransform {

  constructor(
    public _translateService: TranslateService,
  ) {}

  transform(value: string,quantity: number, ...args: any[]): string {    
    
    const language: string = this._translateService.currentLang.slice(0,2);
    const oneletter = value.slice(-1);
    const twoletters = value.slice(-2)
    
    if (quantity === 1 ) return value;
    if (language === 'en'){
      if (value === 'Coupon' || value === 'coupon') return value + 's'; 
      switch (twoletters) {
        case 'ss'||'sh'||'ch':
          return value+'es';
        case 'us':
          return value.replace(/.{0,2}$/,"i");
        case 'is':
          return value.replace(/.{0,2}$/,"es");
        case 'on':
          return value.replace(/.{0,2}$/,"a");
        case 'nd':
          return value;
      };

      switch (oneletter) {
        case 'y':
          return value.replace(/.$/,"ies");
        case 'o':
          return value+'es';
        default:
          return value+'s';
      }

    } else if (language === 'es'){
      
      switch (twoletters) {
        case 'ón'||'sh'||'ch':
          return value.replace(/.{0,2}$/,"ones");
      };

      switch (oneletter) {
        case 'd'||'j'||'l'||'n'||'r':
          return value+'es';
        case 'z':
          return value.replace(/.$/,"ces");
        default:
          return value+'s';
      }
    } else {
      return value;
    }
  }
}