import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CoreDirectivesModule } from '@core/directives/directives';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { ChangeGenderWordPipe } from 'app/shared/pipes/change-gender-word.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { PluralizePipe } from 'app/shared/pipes/pluralize.pipe';
import { GroupByPipe } from 'app/shared/pipes/group-by.pipe';




@NgModule({
  declarations: [ChangeGenderWordPipe, PluralizePipe, GroupByPipe],
  imports: [CommonModule, FlexLayoutModule, FormsModule, ReactiveFormsModule, CoreDirectivesModule, CorePipesModule, TranslateModule],
  exports: [CommonModule, FlexLayoutModule, FormsModule, ReactiveFormsModule, CoreDirectivesModule, CorePipesModule, TranslateModule, ChangeGenderWordPipe, PluralizePipe, GroupByPipe]
})
export class CoreCommonModule {}
